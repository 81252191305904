import authReducer from './authReducer';
import photobookReducer from './photobookReducer';
import downloadReducer from './downloadReducer';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authReducer,
    photobook: photobookReducer,
    download: downloadReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;