const initState = {};

const authReducer = (state = initState, action) => {
    switch (action.type){
        case "SIGNED_IN":
            return {
                ...state,
                user: action.authResult.user
            };
        
        case "SIGN_IN_SHOWN":
            return state;

        default:
            return state;
    }
}

export default authReducer;