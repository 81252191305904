import firebaseui from 'firebaseui';
import firebase from './fbConfig';

const authui = new firebaseui.auth.AuthUI(firebase.auth());

export const uiConfig = {
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    // Privacy policy url.
    privacyPolicyUrl: 'https://eventbug.app/privacypolicy',
    tosUrl: 'https://eventbug.app/tos'
};

export default authui;