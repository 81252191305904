import React, {Component} from 'react';
import { connect } from 'react-redux';

import { deleteAccount, signOutAndStay } from '../../store/actions/authActions';

import SignIn from './SignIn';

//import ImageGallery from 'react-image-gallery';
//import "react-image-gallery/styles/css/image-gallery.css";

class AccountDeletion extends Component {
    constructor(props) {
        super(props);
        
        this.state = {}
    }

    handleDelete = (e) => {
        if(this.props.user.uid) {
            this.props.deleteAccount();

            window.location.replace("/");
        }
    }

    render() {

        if(!this.props.match.params.confirm){
            this.props.signOutAndStay();
            window.location.replace("/deleteaccount/confirm");

            return;
        }

        var messageBox = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="center-align">
                        <p className="center-align text-bold">Are you sure you want to delete your account?</p>
                        <button className="center-align waves-effect waves-light btn" onClick={this.handleDelete}>Delete Account</button>
                        <p className="center-align text-bold"><a href="https://web.eventbug.app">Cancel</a></p>
                    </div>
                </div>
            </div>  
        );

        var loginMessage = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <p className="center-align text-bold">Please sign in to delete your account.</p>
                </div>
            </div>  
        );

        var loginBox = (<SignIn showLogout={true} />);

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/feature-square.png" alt="EventBug.social" className="center" />
                    </div>                    
                </div>

                {this.props.user.uid ? messageBox : loginMessage}

                {this.props.user.uid ? null : loginBox}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    var { firestore, firebase } = state; 

    var userData = null;
    if(firestore.ordered.userData)
        userData = firestore.ordered.userData[0];

    return {
        user: firebase.auth,
        userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteAccount: () => dispatch(deleteAccount(dispatch)),
        signOutAndStay: () => dispatch(signOutAndStay(dispatch))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDeletion);