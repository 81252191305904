import React, {Component} from 'react';
import PhotoGrid from './PhotoGrid';

import qs from 'query-string';

class Embedded extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: "white",
            textColor: "darkgray",
            mode: "full",
            showHeading: false,
            showSharing: true,
            orderBy: null,
            dir: null,
        };

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        console.log(queryParams);

        let mode = queryParams.mode;
        if(mode !== null)
            this.state.mode = mode;

        this.state.showHeading = queryParams.heading === "true";
        this.state.showSharing = queryParams.sharing !== "false";
        
        let bkColor = queryParams.bk || this.props.match.params.bkColor;
        if(bkColor != null && bkColor.length > 0)
            this.state.backgroundColor = bkColor;

        let tColor = queryParams.t || this.props.match.params.tColor;
        if(tColor != null && tColor.length > 0)
            this.state.textColor = tColor;

        let ob = queryParams.ob || this.props.match.params.ob;
        if(ob != null && ob.length > 0)
            this.state.orderBy = ob;
        
        let dir = queryParams.dir || this.props.match.params.dir;
        if(dir != null && dir.length > 0)
            this.state.dir = dir;
    }

    render() {

        let embedStyle = {
            "backgroundColor": this.state.backgroundColor, 
            "color": this.state.textColor
        };

        let scrollStyle = {};
        let footerStyle = {
            "textAlign": "right",
            "paddingRight": "50px",
            "paddingTop": "8px",
            "width": "100%"
        };

        if(this.state.mode === "fit"){
            embedStyle["position"] = "absolute";
            embedStyle["width"] = "100%";
            embedStyle["height"] = "100%";

            scrollStyle = {
                "position": "absolute",
                "overflowY": "auto",
                "width": "100%",
                "top": "0px",
                "bottom": "35px",
                "padding": "8px"
            }

            footerStyle["position"] = "absolute";
            footerStyle["bottom"] = "8px";
            footerStyle["right"] = "8px";
        } 

        let footerText = {
            "color": this.state.textColor,
        }

        let footerLogo = {
            "backgroundColor": this.state.textColor,
        }

        return (
            <div style={embedStyle}>
                <div style={scrollStyle}>
                    <PhotoGrid eventId={this.props.match.params.id} 
                                showHeading={this.state.showHeading} 
                                showSharing={this.state.showSharing}
                                orderBy={this.state.orderBy}
                                dir={this.state.dir} />
                </div>

                <div className="footerPowered" style={footerStyle}>
                    <a href="https://eventbug.social/" target="_blank" rel="noopener noreferrer">
                        <svg className="logo" style={footerLogo} height="25px" width="25px" alt="EventBug.social"/>
                        <span style={footerText}> Powered by EventBug™</span>  
                    </a>  
                </div>
            </div>
        );
    }
}

export default Embedded;