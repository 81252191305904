import React, {Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { isMobile } from "react-device-detect";
import Lightbox from 'react-images';

import Gallery from 'react-photo-gallery'
import LazyPhoto from './LazyPhoto';

//import ImageGallery from 'react-image-gallery';
//import "react-image-gallery/styles/css/image-gallery.css";

import { isAvailable, findPossiblePlatform, findPossiblePlatformFromImage } from '../../common/eventHelpers';

class PhotoGrid extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            readyForDownload: false,
            lightboxIsOpen: false,
            currentImage: 0
        }
    }

    handleImageClick = (e, o) => {
        console.log(o);
        this.setState({ lightboxIsOpen: true, currentImage: o.index });
    }

    closeLightbox = () => {
        this.setState({ lightboxIsOpen: false });
    }

    gotoPrevious = () =>{
        let selectIndex = 0;
        if(this.state.currentImage > 0)
            selectIndex = this.state.currentImage - 1;

        this.setState({ currentImage: selectIndex });
    }

    gotoNext = () => {
        let selectIndex = 0;
        if(this.state.currentImage < this.props.photos.length)
            selectIndex = this.state.currentImage + 1;

        this.setState({ currentImage: selectIndex });
    }

    checkColumnCount = () => {
        if(isMobile)
            return 1;

        return 4;
    }

    handleActionClick = () => {
        window.location.href = "/download/" + this.props.eventId;
    }

    render(){
        var event = this.props.event;
        var messageText = this.props.messageText;
        var status = this.props.statusText;
        var profileImage = this.props.profileImage;

        if(!this.props.eventId || this.props.eventId.length < 1){
            messageText = "Sorry, we could not find the event you are looking for.";
        } 

        let photos = null;
        if(this.props.photos){
            photos = this.props.photos.filter(function(photo) {
                if (photo.deleted)
                    return false; // skip

                if (photo.imagePath === null || !photo.imagePath.includes("http"))
                    return false; // skip
                
                if (photo.thumbPath === null || !photo.thumbPath.includes("http"))
                    return false; // skip

                return true;
            }).map(function(photo, i){
                return {
                    src: photo.imagePath,
                    original: photo.imagePath,
                    thumbnail: photo.thumbPath,
                    width: parseInt(photo.imageWidth || 720),
                    height: parseInt(photo.imageHeight || 1280)
                };
            });
        }

        let allowSharing = false;
        let shareUrl = window.location.href;
        if(window.ebEvent)
            shareUrl = "https://eventbug.app/photobook/" + window.ebEvent.id;

        if(event !== null){
            if(event.allowSharing === true)
                allowSharing = true;

            shareUrl = "https://eventbug.app/photobook/" + event.id;

            console.log("Permissions: Privacy: " + event.privacy + " Sharing: " + event.allowSharing);
        }

        var messageBox = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <p className="center-align text-bold messageText">{ messageText } </p>
                    { status ? <p className="center-align text-bold messageText">{status}</p> : null }
                </div>
            </div>  
        );
        
        var userImage = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="center-align">
                        <img className="responsive-img circle" alt="" style={{width: 50, height: 50}} src={ this.props.profileImage } />
                        <p className="center-align" style={{fontSize: 12}}>(the correct account's profile picture)</p>
                    </div>
                </div>
            </div>  
        );

        let shareStyle = {"display": "none"};
        if(this.props.showSharing !== false && allowSharing)
            shareStyle["display"] = "block";

        window.galleryScroller = document.body;

        return (
            <div className="row">

                {this.props.showHeading && event !== null ? <h5 className="center-align">{event.name}</h5> : null }

                {this.props.canDownload && this.props.showDownload ? 
                <div className="center-align" >
                    <button className="waves-effect waves-light btn" onClick={this.handleActionClick}>
                        <i className="material-icons left">cloud</i>Download
                    </button> 
                </div> : null }

                <div className="right-align" style={shareStyle} >
                    <div className="fb-share-button" 
                        data-href={shareUrl} 
                        data-layout="button_count">
                    </div>
                </div>

                { messageText ? messageBox : null }
                { profileImage ? userImage : null }

                { this.props.downloadReady ?  <Gallery photos={photos} columns={this.checkColumnCount} onClick={this.handleImageClick} ImageComponent={LazyPhoto} /> : null }

                <Lightbox
                    images={photos}
                    isOpen={this.state.lightboxIsOpen}
                    currentImage={this.state.currentImage}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    onClose={this.closeLightbox}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    console.log(state);

    var { firestore, firebase, download } = state; 

    var events = firestore.ordered.events;
    var photos = firestore.ordered.photos;
    
    var event = null;
    if(events && events[0])
        event = events[0];

    if(!photos)
        photos = [];

    var userId = firebase.auth.uid;

    var requested = true;
    if(firestore.status){
        Object.keys(firestore.status.requesting).forEach((key) => {
            if(key.indexOf("events") >= 0 && firestore.status.requesting[key])
                requested = false;
        });
    }

    var statusCode = isAvailable(event, userId);
    var canDownload = (statusCode === 200);

    var downloadReady = false;
    var messageText = null;
    var statusText = null;
    var profileImage= null;
    var showLogout = false;
    if(requested) {
        if(!event){
            if(userId == null)
                messageText = "Please sign in with Google or Facebook to view this event. No account? click sign in to create one.";
            else
                messageText = "Sorry, we could not find the event you are looking for.";

        } else if(statusCode === 423) {
            messageText = "This event doesn't have any photos yet. Please check back later."; //Hasn't started yet.

        } else if(!event.allowPhotobook && statusCode === 401) {
            messageText = "You do not have permission to access this event.";
            showLogout = true;
            
            var possiblePlatform = findPossiblePlatform(event, firebase.auth.displayName);
            var currentPlatform = findPossiblePlatformFromImage(firebase.auth.photoURL);
            if(possiblePlatform) { 
                var subtext = null;
                if(currentPlatform !== possiblePlatform.platformName)
                    subtext = (<span>You're currently signed in using { currentPlatform }. <code><b>;)</b></code></span>);

                statusText = (<span>It looks like you were signed in using <b>{ possiblePlatform.platformName }</b>. { subtext }</span>);
                profileImage = possiblePlatform.platformImage;
            } else {
                statusText = "Make sure you are logged in using the same account you used for this event on EventBug.";
            }

        } else if(photos.length < 1){
            messageText = "This event doesn't have any photos yet. Please check back later.";
        } else {
            downloadReady = true;
        }
    }

    return {
        user: firebase.auth,
        event,
        photos,

        showLogout,
        downloadReady,
        canDownload,
        messageText,
        statusText,
        profileImage,

        downloading: download.downloading,
        photoNumber: download.photoNumber,
        total: download.total,
        downloadError: download.err
    }
}

export default compose(
        connect(mapStateToProps),
        firestoreConnect((props) => {
            if(!props.eventId || props.eventId.length < 1) return [];

            let orderBy = ["takenOn", "desc"];
            if(props.orderBy && props.orderBy.length > 1)
                orderBy[0] = props.orderBy;

            if(props.dir && props.dir.length > 1)
                orderBy[1] = props.dir;

            return [{
                collection: 'events',
                doc: props.eventId,
                storeAs: "events"
            },
            {
                collection: 'events',
                doc: props.eventId,
                subcollections: [{ collection: "photos" }],
                orderBy: orderBy,
                where: ["deleted", "==", false],
                storeAs: "photos"
            }];
        })
    )(PhotoGrid);