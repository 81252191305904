import React, {Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import SignIn from '../auth/SignIn';
import EventCard from './EventCard';

//import ImageGallery from 'react-image-gallery';
//import "react-image-gallery/styles/css/image-gallery.css";

class EventList extends Component {
    constructor(props) {
        super(props);
        
        this.state = {}
    }

    render(){

        var messageBox = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <p className="center-align">You don't have any events yet.</p>
                    <p className="center-align text-bold"><a href="https://eventbug.page.link/Download">Open EventBug</a> and create an event.</p>
                </div>
            </div>  
        );

        let showIds = false;
        if(this.props.userData != null && this.props.userData.admin)
            showIds = true;

        let gridRows = [];
        let row = [];
        if(this.props.events){
            this.props.events.forEach(event => {
                
                row.push((<EventCard event={event} showId={showIds} key={event.id} />));

                if(row.length >= 3){
                    gridRows.push(row);
                    row = [];
                }
            });

            if(row.length > 0){
                gridRows.push(row);
                    row = [];
            }
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/feature-square.png" alt="EventBug.social" className="center" />
                    </div>                    
                </div>

                {this.props.user && gridRows.length < 1 ? messageBox : null}

                {this.props.user != null ? gridRows.map((row, index) => (
                    <div className="row" key={index}>{row}</div>
                )) : null }

                <SignIn showLogout={true} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    var { firestore, firebase } = state; 

    var userData = null;
    if(firestore.ordered.userData)
        userData = firestore.ordered.userData[0];

    var events = firestore.ordered.events;

    return {
        user: firebase.auth,
        userData,
        events
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        if (!props.user.uid) return [];

        return [
        {
            collection: 'events',
            where: ['usersList', 'array-contains', props.user.uid],
            orderBy: ["startDate", "desc"],
            storeAs: "events"
        },
        {
            collection: 'users',
            doc: props.user.uid,
            storeAs: "userData"
        }];
    })
)(EventList);