import React, { Component } from 'react';

class SubmitBug extends Component {

    handleClick() {
        if(window.showCollectorDialog)
            window.showCollectorDialog();
    }

    componentDidMount(){
        setTimeout(() => {
            this.handleClick();
        } ,2500);
    }

    render(){

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/feature-square.png" alt="EventBug Logo" className="center" />
                    </div>    
                </div>

                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <div className="center-align">
                            <p className="center-align">Having a problem with EventBug? Submit your feedback here and we will get it fixed as soon as possible. Thank you for your patience.</p>
                            <button disabled={this.props.downloading} className="waves-effect waves-light btn" onClick={this.handleClick}>Submit a Bug</button>
                        </div> 
                    </div>    
                </div>
            </div>
        );
    }
}

export default SubmitBug;