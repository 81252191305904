import React, { Component } from 'react';

class TOS extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <a href="/">
                            <img src="/feature-square.png" alt="EventBug Logo" className="center" />
                        </a>
                    </div>
                </div>

                <div className="row">
                    <h4>Terms and Conditions</h4>
                    <br />

                    <h5>Agreement between User and EventBug.social</h5>
                    <p>Welcome to EventBug.social. The
                        EventBug.social ("EventBug") website and application (the &quot;Site&quot;) is comprised of various web pages and applications operated by PhotoBug.social,
                        LLC. EventBug.social is offered to you conditioned on your acceptance without
                        modification of the terms, conditions, and notices contained herein (the &quot;Terms&quot;). Your use of
                        EventBug.social constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>

                    <p>EventBug.social is a Social Networking Site.</p>

                    <p>The purpose of EventBug is to
                        allow users to collaborate family-friendly photos with each other in a shared gallery or event. EventBug will
        allow users to share, download and print photos from an event or gallery they are apart of.</p>
                    <br />

                    <h5>Privacy</h5>
                    <p>Your use of EventBug.social is subject to EventBug&#39;s Privacy Policy. Please review our Privacy Policy, which also governs the Site and
        informs users of our data collection practices.</p>
                    <br />

                    <h5>Electronic Communications</h5>
                    <p>Visiting EventBug.social or
                        sending emails to EventBug constitutes electronic communications. You consent to receive electronic
                        communications and you agree that all agreements, notices, disclosures and other communications that we provide
                        to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in
        writing.</p>
                    <br />

                    <h5>Children Under Thirteen</h5>
                    <p>EventBug does not knowingly
                        collect, either online or offline, personal information from persons under the age of thirteen. If you are
        under 18, you may use EventBug.social only with permission of a parent or guardian.</p>
                    <br />

                    <h5>Links to Third Party Sites/Third Party Services</h5>
                    <p >EventBug.social may contain links
                        to other websites (&quot;Linked Sites&quot;). The Linked Sites are not under the control of EventBug and
                        EventBug is not responsible for the contents of any Linked Site, including without limitation any link
                        contained in a Linked Site, or any changes or updates to a Linked Site. EventBug is providing these links to
                        you only as a convenience, and the inclusion of any link does not imply endorsement by EventBug of the site or
        any association with its operators.</p>

                    <p>Certain services made available
                        via EventBug.social are delivered by third party sites and organizations. By using any product, service or
                        functionality originating from the EventBug.social domain, you hereby acknowledge and consent that EventBug may
                        share such information and data with any third party with whom EventBug has a contractual relationship to
        provide the requested product, service or functionality on behalf of EventBug.social users and customers.</p>
                    <br />

                    <h5>No Unlawful or Prohibited Use/Intellectual Property</h5>
                    <p>You are granted
                        a non-exclusive, non-transferable, revocable license to access and use EventBug.social strictly in accordance
                        with these terms of use. As a condition of your use of the Site, you warrant to EventBug that you will not use
                        the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner
                        which could damage, disable, overburden, or impair the Site or interfere with any other party&#39;s use and
        enjoyment of the Site.</p>

                    <p>You may not obtain or attempt to
                        obtain any materials or information through any means not intentionally made available or provided for through
        the Site.</p>

                    <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used
                        on the Site, is the property of EventBug or its suppliers and protected by copyright and other laws that
                        protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other
                        proprietary notices, legends or other restrictions contained in any such content and will not make any changes
        thereto.</p>

                    <p>You will not modify, publish,
                        transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit
                        any of the content, in whole or in part, found on the Site. EventBug content is not for resale. Your use of the
                        Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not
                        delete or alter any proprietary rights or attribution notices in any content. You will use protected content
                        solely for your personal use, and will make no other use of the content without the express written permission
                        of EventBug and the copyright owner. You agree that you do not acquire any ownership rights in any protected
                        content. We do not grant you any licenses, express or implied, to the intellectual property of EventBug or our
        licensors except as expressly authorized by these Terms.</p>
                    <br />

                    <h5>Use of Communication Services</h5>
                    <p>The Site may contain bulletin
                        board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other
                        message or communication facilities designed to enable you to communicate with the public at large or with a
                        group (collectively, &quot;Communication Services&quot;). You agree to use the Communication Services only to
                        post, send and receive messages and material that are proper and related to the particular Communication Service.</p>
                        
                    <p>By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk,
                        threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish,
                        post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent
                        or unlawful topic, name, material or information; upload files that contain software or other material
                        protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the
                        rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or
                        any other similar software or programs that may damage the operation of another&#39;s computer; advertise or
                        offer to sell or buy any goods or services for any business purpose, unless such Communication Service
                        specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters;
                        download any file posted by another user of a Communication Service that you know, or reasonably should know,
                        cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper
        notices or proprietary designations or labels of the</p>

                    <p>origin or source of software or other material contained in a file that is uploaded; restrict or inhibit any other user from
                        using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be
                        applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.</p>

                    <p>EventBug has no obligation to monitor the Communication Services. However, EventBug reserves the right to review materials posted to a
                        Communication Service and to remove any materials in its sole discretion. EventBug reserves the right to
                        terminate your access to any or all of the Communication Services at any time without notice for any reason
        whatsoever.</p>

                    <p>EventBug reserves the right at
                        all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or
                        governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in
        part, in EventBug&#39;s sole discretion.</p>

                    <p>Always use caution when giving
                        out any personally identifying information about yourself or your children in any Communication Service.
                        EventBug does not control or endorse the content, messages or information found in any Communication Service
                        and, therefore, EventBug specifically disclaims any liability with regard to the Communication Services and any
                        actions resulting from your participation in any Communication Service. Managers and hosts are not authorized
        EventBug spokespersons, and their views do not necessarily reflect those of EventBug.</p>

                    <p>Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are
        responsible for adhering to such limitations if you upload the materials.</p>
                    <br />

                    <h5>Materials Provided to EventBug.social or Posted on Any EventBug Web Page</h5>
                    <p>EventBug does not claim ownership
                        of the materials you provide to EventBug.social (including feedback and suggestions) or post, upload, input or
                        submit to any EventBug Site or our associated services (collectively &quot;Submissions&quot;). However, by
                        posting, uploading, inputting, providing or submitting your Submission you are granting EventBug, our
                        affiliated companies and necessary sublicensees permission to use your Submission in connection with the
                        operation of their Internet businesses including, without limitation, the rights to: copy, distribute,
                        transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to
        publish your name in connection with your Submission.</p>

                    <p>No compensation will be paid with respect to the use of your Submission, as provided herein. EventBug is under no obligation to post or use any
        Submission you may provide and may remove any Submission at any time in EventBug&#39;s sole discretion.</p>

                    <p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the
                        rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>
                    <br />

                    <h5>Third Party Accounts</h5>
                    <p>You will be able to connect your EventBug account to third party accounts. By connecting your EventBug account to your third party account, you
                        acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites).</p>
                    <br />

                    <h5>International Users</h5>
                    <p>The Service is controlled, operated and administered by EventBug from our offices within the USA. If you access the Service from a
                        location outside the USA, you are responsible for compliance with all local laws. You agree that you will not
                        use the EventBug Content accessed through EventBug.social in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
                    <br />

                    <h5>Indemnification</h5>
                    <p>You agree to indemnify, defend
                        and hold harmless EventBug, its officers, directors, employees, agents and third parties, for any losses,
                        costs, liabilities and expenses (including reasonable attorney&#39;s fees) relating to or arising out of your
                        use of or inability to use the Site or services, any user postings made by you, your violation of any terms of
                        this Agreement or your violation of any rights of a third party, or your violation of any applicable laws,
                        rules or regulations. EventBug reserves the right, at its own cost, to assume the exclusive defense and control
                        of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with
                        EventBug in asserting any available defenses.</p>
                    <br />

                    <h5>Class Action Waiver</h5>
                    <p>Any arbitration under these Terms
                        and Conditions will take place on an individual basis; class arbitrations and class/representative/collective
                        actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH&#39;S
                        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE</p>

                    <p>OTHER. Further, unless both you
                        and EventBug agree otherwise, the arbitrator may not consolidate more than one person&#39;s claims, and may not
        otherwise preside over any form of a representative or class proceeding.</p>
                    <br />

                    <h5>Liability Disclaimer</h5>
                    <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
                        ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. EVENTBUG.SOCIAL, LLC AND/OR ITS SUPPLIERS MAY
        MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>

                    <p>EVENTBUG.SOCIAL, LLC AND/OR ITS
                        SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
        THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND</p>

                    <p>RELATED GRAPHICS
                        CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
                        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION
                        OF ANY KIND. EVENTBUG.SOCIAL, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                        REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES
        OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

                    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EVENTBUG.SOCIAL, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
                        INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
                        LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                        PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR
                        FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED
                        THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
                        STRICT LIABILITY OR OTHERWISE, EVEN IF EVENTBUG.SOCIAL, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
                        POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
                        FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
                        WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
        DISCONTINUE USING THE SITE.</p>
                    <br />

                    <h5>Termination/Access Restriction</h5>
                    <p>EventBug reserves the right, in
                        its sole discretion, to terminate your access to the Site and the related services or any portion thereof at
                        any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the
                        State of Colorado and you hereby consent to the exclusive jurisdiction and venue of courts in Colorado in all
                        disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction
        that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

                    <p>You agree that no joint venture,
                        partnership, employment, or agency relationship exists between you and EventBug as a result of this agreement
                        or use of the Site. EventBug&#39;s performance of this agreement is subject to existing laws and legal process,
                        and nothing contained in this agreement is in derogation of EventBug&#39;s right to comply with governmental,
                        court and law enforcement requests or requirements relating to your use of the Site or information provided to
                        or gathered by EventBug with respect to such use. If any part of this agreement is determined to be invalid or
                        unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability
        limitations set forth above, then the invalid or unenforceable provision will be deemed</p>

                    <p>superseded by a
                        valid, enforceable provision that most closely matches the intent of the original provision and the remainder
        of the agreement shall continue in effect.</p>

                    <p>Unless otherwise specified
                        herein, this agreement constitutes the entire agreement between the user and EventBug with respect to the Site
                        and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or
                        written, between the user and EventBug with respect to the Site. A printed version of this agreement and of any
                        notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or
                        relating to this agreement to the same extent and subject to the same conditions as other business documents
                        and records originally generated and maintained in printed form. It is the express wish to the parties that
        this agreement and all related documents be written in English.</p>
                    <br />

                    <h5>Changes to Terms</h5>
                    <p>EventBug reserves the right, in
                        its sole discretion, to change the Terms under which EventBug.social is offered. The most current version of
                        the Terms will supersede all previous versions. EventBug encourages you to periodically review the Terms to
        stay informed of our updates.</p>
                    <br />

                    <h5>Contact Us</h5>

                    <p>EventBug welcomes your questions or comments regarding this Statement of Privacy. If you believe that EventBug has not adhered to this Statement, please contact EventBug at:</p>

                    <p>
                    PhotoBug.social, LLC <br/>
                    121 S. Tejon Street, Suite 900 <br/>
                    Colorado Springs, CO 80903, United States
                    </p>

                    <p>
                    Email Address: <br />
                    info@intrigued.me
                    </p>

                    <p>
                    Telephone number:<br />
                    (303) 309-0071
                    </p>
                </div>
            </div>
        );
    }
}

export default TOS;