export const isAvailable = (event, userId) => {
    if(event === null)
        return 404;

    if(event.startDate === null || event.endDate === null)
        return 500;

    var now = new Date();
    var startDate = event.startDate.toDate();
    //var endDate = event.startDate.toDate();

    if(now.getTime() < startDate.getTime())
        return 423;

    //if(now.getTime() > endDate.getTime())
        //return 423;

    var validPermission = 401;     
    if(userId !== null){
        if(event.hostId === userId)
            return 200;

        if(event.permissionsList){
            event.permissionsList.forEach(permission => {
                if(permission.userId !== userId)
                    return true;

                if(permission.banned) {
                    return false;
                }

                if(!permission.inviteAccepted)
                    return false;                        
                    
                validPermission = 200;
                return false;
            });
        }
    }

    return validPermission;
}

export const findPossiblePlatform = (event, username) => {

        if(!event.permissionsList)
            return null;

        var platformName = null;
        var platformImage = null;
        event.permissionsList.forEach(permission => {
            if(permission.username !== username)
                return true;                    
            
            if(!permission.userImage)
                return false;

            platformName = findPossiblePlatformFromImage(permission.userImage);
            platformImage = permission.userImage;

            return false;
        });

        if(platformName === null)
            return null;
        
        return { platformName, platformImage };
    }

    export const findPossiblePlatformFromImage = (userImage) => {            
        if(!userImage)
            return null;

        var platformName = null;
        if(userImage.indexOf("facebook") >= 0)
            platformName = "Facebook";
        else if(userImage.indexOf("google") >= 0)
            platformName = "Google";
        
        return platformName;
    }