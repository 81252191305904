import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyDXmGbh9sYvagVByqE8rTBJPnV2tnYRLCw",
    authDomain: "photobug-35272.firebaseapp.com",
    databaseURL: "https://photobug-35272.firebaseio.com",
    projectId: "photobug-35272",
    storageBucket: "photobug-35272.appspot.com",
    messagingSenderId: "689636622573"
};

firebase.initializeApp(config);

export default firebase;