import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn, signOut } from '../../store/actions/authActions';

class SignIn extends Component {

    handleLogout = (e) => {
        this.props.signOut();
    }

    render() {
        var content = null;
        if(this.props.user.uid){
            if(this.props.showLogout)
                content = (<button className="waves-effect waves-light btn" onClick={this.handleLogout}>Switch Users</button>);

        } else if(this.props.user.isLoaded)
            this.props.signIn();

        return(
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div id="firebaseui-auth-container"></div>
                    <div className="center-align">
                        { content }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ firebase }) => {
    return {
        user: firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: () => dispatch(signIn(dispatch)),
        signOut: () => dispatch(signOut(dispatch))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);