import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import SignIn from '../auth/SignIn';

class Home extends Component {

    handleActionClick = () => {
        window.location.href = 'https://eventbug.app/events';
    }

    render(){

        var actionButton = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="center-align">
                        <button className="waves-effect waves-light btn" onClick={this.handleActionClick}><i className="material-icons left">cloud</i>My Events</button>
                    </div>
                </div>
            </div>  
        )

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/img/eventbug_while_nofill_tm.png" alt="EventBug Logo" className="center" title="EventBug is a registered trademark of PhotoBug.social, LLC" />
                        <span className="centertext" title="EventBug is a trademark of PhotoBug.social, LLC">EventBug.social Beta</span>
                        <span className="centertext tagline">Coming soon to iOS devices</span>
                    </div>    
                </div>
                
                {this.props.userId ? actionButton : null}
                
                <SignIn showLogout={true} />    

                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <div className="center-align">
                            <a href='https://play.google.com/store/apps/details?id=me.intrigued.eventbug&utm_source=web&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{"width": "200px"}}/></a>
                        </div>
                    </div>    
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    console.log(state);

    var { firebase } = state; 

    var userId = firebase.auth.uid;
    return {
        user: firebase.auth,
        userId
    }
}

export default compose(
        connect(mapStateToProps)
    )(Home);