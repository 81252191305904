import React, {Component} from 'react';
import PhotoGrid from './PhotoGrid';
import { FacebookProvider, Share } from 'react-facebook';

import SignIn from '../auth/SignIn';

//import ImageGallery from 'react-image-gallery';
//import "react-image-gallery/styles/css/image-gallery.css";

class PhotoBook extends Component {
    constructor(props) {
        super(props);
        
        this.state = {}
    }

    render(){
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/feature-square.png" alt="EventBug.social" className="center" />
                    </div>                    
                </div>

                <PhotoGrid eventId={this.props.match.params.id} showHeading={true} showDownload={true} />
                <SignIn showLogout={this.props.showLogout} />
            </div>
        );
    }
}

export default PhotoBook;