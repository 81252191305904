import React, { Component } from 'react';

class PrivacyPolicy extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <a href="/">
                            <img src="/feature-square.png" alt="EventBug Logo" className="center" />
                        </a>
                    </div>    
                </div>

                <div className="row">
                    <div id="section1">
                        <h4>Privacy Policy</h4>

                        <p>Protecting your private information is our priority. This Statement of Privacy applies to EventBug and EventBug.social and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to EventBug include EventBug.social. The EventBug website is a photo collaboration mobile application and site. By using the EventBug website, you consent to the data practices described in this statement.</p>
                    </div>
                    <br />

                    <div id="section3">
                        <h5>Collection of your Personal Information</h5>

                        <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through EventBug's public message boards, this information may be collected and used by others.</p>

                        <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
                    </div>
                    <br />

                    <div id="section5">
                        <h5>Sharing Information with Third Parties</h5>

                        <p>EventBug does not sell, rent or lease its customer lists to third parties.</p>

                        <p>EventBug may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. EventBug may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to EventBug, and they are required to maintain the confidentiality of your information.</p>

                        <p>EventBug may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on EventBug or the site; (b) protect and defend the rights or property of EventBug; and/or (c) act under exigent circumstances to protect the personal safety of users of EventBug, or the public.</p>
                    </div>
                    <br />

                    <div id="section6">
                        <h5>Tracking User Behavior</h5>

                        <p>EventBug may keep track of the websites and pages our users visit within EventBug, in order to determine what EventBug services are the most popular. This data is used to deliver customized content and advertising within EventBug to customers whose behavior indicates that they are interested in a particular subject area.</p>
                    </div>
                    <br />

                    <div id="section7">
                        <h5>Automatically Collected Information</h5>

                        <p>Information about your computer hardware and software may be automatically collected by EventBug. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the EventBug website.</p>
                    </div>
                    <br />

                    <div id="section8">
                        <h5>Use of Cookies</h5>

                        <p>The EventBug website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>

                        <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize EventBug pages, or register with EventBug site or services, a cookie helps EventBug to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same EventBug website, the information you previously provided can be retrieved, so you can easily use the EventBug features that you customized.</p>

                        <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the EventBug services or websites you visit.</p>
                    </div>
                    <br />

                    <div id="section9">
                        <h5>Links</h5>

                        <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
                    </div>
                    <br />

                    <div id="section10">
                        <h5>Security of your Personal Information</h5>

                        <p>EventBug secures your personal information from unauthorized access, use, or disclosure. EventBug uses the following methods for this purpose:</p>    
                        
                        <ul>
                            <li>SSL Protocol</li>
                        </ul>

                        <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>

                        <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                    </div>
                    <br />

                    <div id="section13">
                        <h5>Children Under Thirteen</h5>

                        <p>EventBug does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
                    </div>
                    <br />

                    <div id="section16">
                        <h5>E-mail Communications</h5>

                        <p>From time to time, EventBug may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from EventBug or click on a link therein.</p>
                    </div>
                    <br />

                    <div id="section17">
                        <h5>External Data Storage Sites</h5>

                        <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
                    </div>
                    <br />

                    <div id="section18">
                        <h5>Changes to this Statement</h5>

                        <p>EventBug reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
                        <br />
                    </div>
                    <br />

                    <div id="section19">
                        <h5>Contact Information</h5>

                        <p>EventBug welcomes your questions or comments regarding this Statement of Privacy. If you believe that EventBug has not adhered to this Statement, please contact EventBug at:</p>

                        <p>
                        PhotoBug.social, LLC <br/>
                        121 S. Tejon Street, Suite 900 <br/>
                        Colorado Springs, CO 80903, United States
                        </p>

                        <p>
                        Email Address: <br />
                        info@intrigued.me
                        </p>

                        <p>
                        Telephone number:<br />
                        (303) 309-0071
                        </p>

                        <p>Effective as of November 01, 2018</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;