import authui, { uiConfig } from '../../config/fbAuth';

export const signIn = () => {
    return (dispatch, getState) => {

        uiConfig.callbacks = {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                dispatch({type: 'SIGNED_IN', authResult});
                window.location.reload();

                return false;
            },
            uiShown: function() {
                dispatch({ type: 'SIGN_IN_SHOWN' });
            }
        };

        // The start method will wait until the DOM is loaded.
        authui.start('#firebaseui-auth-container', uiConfig);   
    };
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNED_OUT'});
            window.location.reload();
        }) 
    };
}

export const signOutAndStay = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNED_OUT'});
        }) 
    };
}

export const deleteAccount = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebaseAuth = getFirebase().auth();
        const currentUser = firebaseAuth.currentUser;

        console.log(firebaseAuth.currentUser);

        if(currentUser && currentUser.uid) {
            currentUser.delete().then(() => {
                alert("User deleted successfully. This window can now be closed.");
                console.log("Successfully deleted user. Logging out.");
    
                firebaseAuth.signOut();

                dispatch({type: 'SIGNED_OUT'});
                window.location.reload();
            });
        } else {
            alert("Failed to delete user. Please login and try again.");
            console.log("No user to delete.");
        }
    };
}