const initState = {
    downloading: false,
    zipFilename: null,
    total: 0,
    photoNumber: 0,
    err: null
};

const downloadReducer = (state = initState, action) => {
    switch (action.type){
        case "ZIP_STARTED":
            return {
                ...state,
                total: action.total,
                downloading: true
            };

        case "ZIP_PHOTO_ADDED":
            return {
                ...state,
                photoNumber: action.photoNumber
            };

        case "ZIP_CREATED":
            return {
                ...state,
                zipFilename: action.zipFilename,
                downloading: false
            };
        
        case "ZIP_FAILED":
            return {
                ...state,
                err: action.err,
                downloading: false
            };

        default:
            return state;
    }
}

export default downloadReducer;