import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Footer from './components/layout/footer';

import Home from './components/home/Home';
import SubmitBug from './components/home/SubmitBug';
import PhotoBook from './components/photobook/Photobook';
import Download from './components/photobook/Download';

import TOS from './components/legal/tos';
import PrivacyPolicy from './components/legal/privacypolicy';
import SoftwareLicenses from './components/legal/softwarelicenses';
import Embedded from './components/photobook/Embedded';
import EventList from './components/events/EventList';
import AccountDeletion from './components/auth/AccountDeletion';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/embed'>
            <div className="Frame">
              <Switch>
                <Route exact path='/embed/:id' component={Embedded} />
                <Route exact path='/embed/:id/:bkColor' component={Embedded} />
                <Route exact path='/embed/:id/:bkColor/:tColor' component={Embedded} />

                <Route path='/embed/' component={Embedded} />
              </Switch>
            </div>
          </Route>

          <Route path='/'>
            <div className="App">
              <main>
                <Switch>
                  <Route exact path='/submitbug' component={SubmitBug} />
                  <Route exact path='/download/:id' component={Download} />
                  <Route exact path='/photobook/:id' component={PhotoBook} />
                  <Route exact path='/events/' component={EventList} />

                  <Route exact path='/deleteaccount' component={AccountDeletion} />
                  <Route exact path='/deleteaccount/:confirm' component={AccountDeletion} />
                  <Route exact path='/privacypolicy' component={PrivacyPolicy} />
                  <Route exact path='/privacypolicy.html' component={PrivacyPolicy} />
                  <Route exact path='/tos' component={TOS} />
                  <Route exact path='/tos.html' component={TOS} />
                  <Route exact path='/licenses' component={SoftwareLicenses} />
                  <Route exact path='/licenses.html' component={SoftwareLicenses} />

                  <Route path='/' component={Home} />
                  
                </Switch>
              </main>
              <Footer />
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
