import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer>
                <div className="footer-copyright">
                    <div className="container">
                        <div className="row">
                            <div className="center-align">
                                <span className="footerCopyright">EventBug® is a registered trademark of PhotoBug.social, LLC</span> <br />
                                <span className="footerCopyright">Copyright © 2018-2019 by PhotoBug.social, LLC. All Rights Reserved.</span> <br />
                                <a className="grey-text text-lighten-4 footerLink" href="/privacypolicy">Privacy Policy</a>
                                <span> - </span>
                                <a className="grey-text text-lighten-4 footerLink" href="/tos">Terms of Use</a>
                                <span> - </span>
                                <a className="grey-text text-lighten-4 footerLink" href="/licenses">Open source licenses</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;