import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

export const downloadPhotos = () => {
    return (dispatch, getState) => {

        var state = getState();

        var events = state.firestore.ordered.events;
        var photos = state.firestore.ordered.photos;
        
        var event = null;
        if(events && events[0])
            event = events[0];
    
        if(!photos) {
            dispatch({type: 'ZIP_FAILED'});
            return;
        }

        dispatch({type: 'ZIP_STARTED', total: photos.length});

        var eventname = event.name;

        var zip = new JSZip();
        var count = 0;
        var zipFilename = eventname + ".zip";
        
        photos.forEach(function(photo){

            if(!photo.imagePath || photo.imagePath.indexOf("http") < 0){
                count++;
                return true;
            }

            if(photo.deleted){
                count++;
                return true;
            }

            JSZipUtils.getBinaryContent(photo.imagePath, function (err, data) {
                if(err) {
                    dispatch({type: 'ZIP_FAILED', err});
                    throw err;                  
                }

                var filename = "EventBug-Photo (" + (count + 1) + ").jpg";
                count++;

                console.log("Getting image: " + filename);
                zip.file(filename, data, {binary:true});

                dispatch({type: 'ZIP_PHOTO_ADDED', photoNumber: count});

                if (count === photos.length) {
                    console.log("Zipping images.")
                    zip.generateAsync({type:'blob'}).then(function(content) {
                        
                        saveAs(content, zipFilename);
                        dispatch({type: 'ZIP_CREATED', zipFilename});
                        
                    }).catch(err => {
                        dispatch({type: 'ZIP_FAILED', err});
                    });
                }
            });
        });
    };
}