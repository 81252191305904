import React, {Component} from 'react';

class EventCard extends Component {
    render() {
        if(this.props.event === null)
            return(<div>No event</div>);

        let imageView = (<img src="/feature-square.png" alt="Event" />);
        if(this.props.event.eventImage != null && this.props.event.eventImage.includes("http")) 
            imageView = (<img src={this.props.event.eventImage} alt="Event" />);

        let galleryLink = "/photobook/" + this.props.event.id;
        let downloadLink = "/download/" + this.props.event.id;

        return (<div className="col s12 m4">
                    <div className="card" style={{"backgroundColor": "gray", "minHeight": "150px"}}>
                        <div className="card-image" style={{"height": "200px", "overflow": "hidden"}}>
                            {imageView ? imageView : null}
                            <span className="card-title" style={{"backgroundColor": "#A9A9A9EF"}}>{this.props.event.name}</span>
                        </div>
                        <div className="card-content">
                            { this.props.showId ? this.props.event.id : null }
                        </div>
                        <div className="card-action">
                            <a href={galleryLink} rel="noopener noreferrer">Photobook</a>
                        </div>
                        <div className="card-action">
                            <a href={downloadLink} rel="noopener noreferrer">Download</a>
                        </div>
                    </div>
                </div>);
    }
  }

  export default EventCard;
  