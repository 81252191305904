import React, {Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import { isAvailable, findPossiblePlatform, findPossiblePlatformFromImage } from '../../common/eventHelpers';
import { downloadPhotos } from '../../store/actions/downloadActions';

import SignIn from '../auth/SignIn';

import './download.css';

class Download extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            readyForDownload: false
        }
    }
    
    handleAction = (e) => {
        if(!this.props.downloadReady)
            return;

        this.props.downloadPhotos();
    }

    render(){
        var messageText = this.props.messageText;
        var actionText = this.props.actionText;
        var status = this.props.statusText;
        var profileImage = this.props.profileImage;
        
        if(this.props.downloading || this.props.photoNumber > 0){
            status = "Downloading photo (" + this.props.photoNumber + " of " + this.props.total + ")";
            actionText = null;
        }

        if(!this.props.downloading && this.props.photoNumber > 0){
            status = "Download Complete!";
            actionText = null;
        }

        if(this.props.downloadError){
            status = "Download Error: " + this.props.downloadError.message;
            actionText = "Retry";
        }

        var messageBox = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <p className="center-align">{ messageText } </p>
                    { status ? <p className="center-align text-bold">{status}</p> : null }
                </div>
            </div>  
        )

        var actionButton = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="center-align">
                        <button disabled={this.props.downloading} className="waves-effect waves-light btn" onClick={this.handleAction}><i className="material-icons left">cloud</i>{ actionText }</button>
                    </div>
                </div>
            </div>  
        )
        
        var userImage = (
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="center-align">
                        <img class="responsive-img circle" alt="" style={{width: 50, height: 50}} src={ this.props.profileImage } />
                        <p className="center-align" style={{fontSize: 12}}>(the correct account's profile picture)</p>
                    </div>
                </div>
            </div>  
        )

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <img src="/feature-square.png" alt="EventBug.social" className="center" />
                    </div>                    
                </div>        
                { messageText ? messageBox :  null }
                { profileImage ? userImage : null }
                { actionText ? actionButton : null }

                <SignIn showLogout={this.props.showLogout} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    console.log(state);

    var { firestore, firebase, download } = state; 

    var events = firestore.ordered.events;
    var photos = firestore.ordered.photos;
    
    var event = null;
    if(events && events[0])
        event = events[0];

    if(!photos)
        photos = [];

    var userId = firebase.auth.uid;

    var requested = true;
    if(firestore.status){
        Object.keys(firestore.status.requesting).forEach((key) => {
            if(key.indexOf("events") >= 0 && firestore.status.requesting[key])
                requested = false;
        });
    }

    var statusCode = isAvailable(event, userId);

    var downloadReady = false;
    var messageText = null;
    var actionText = null;
    var statusText = null;
    var profileImage= null;
    var showLogout = false;
    if(requested) {
        if(!event){
            if(userId == null)
                messageText = "Please sign in with Google or Facebook to view this event. No account? click sign in to create one.";
            else
                messageText = "Sorry, we could not find the event you are looking for.";
        } else if(statusCode === 423) {
            messageText = "This event either hasn't started yet or unavailable for download. Please check back later.";
        } else if(statusCode === 401) {
            messageText = "You do not have permission to access this event.";
            showLogout = true;
            
            var possiblePlatform = findPossiblePlatform(event, firebase.auth.displayName);
            var currentPlatform = findPossiblePlatformFromImage(firebase.auth.photoURL);
            if(possiblePlatform) { 
                var subtext = null;
                if(currentPlatform !== possiblePlatform.platformName)
                    subtext = (<span>You're currently signed in using { currentPlatform }. <code><b>;)</b></code></span>);

                statusText = (<span>It looks like you were signed in using <b>{ possiblePlatform.platformName }</b>. { subtext }</span>);
                profileImage = possiblePlatform.platformImage;
            } else {
                statusText = "Make sure you are logged in using the same account you used for this event on EventBug.";
            }

        } else if(photos.length < 1){
            messageText = "This event doesn't have any photos to download. Please check back later.";
        } else {
            messageText = "\"" + event.name + "\" is ready for download! Click the link below to download the photos.";
            actionText = "Download Photobook";
            downloadReady = true;
        }
    }

    return {
        user: firebase.auth,
        event,
        photos,

        showLogout,
        downloadReady,
        messageText,
        actionText,
        statusText,
        profileImage,

        downloading: download.downloading,
        photoNumber: download.photoNumber,
        total: download.total,
        downloadError: download.err
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        downloadPhotos: () => dispatch(downloadPhotos(dispatch))
    }
}

export default compose(
        connect(mapStateToProps, mapDispatchToProps),
        firestoreConnect((props) => [
            {
                collection: 'events',
                doc: props.match.params.id,
                storeAs: "events"
            },
            {
                collection: 'events',
                doc: props.match.params.id,
                subcollections: [{ collection: "photos" }],
                storeAs: "photos"
            }])
    )(Download);