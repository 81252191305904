import React, { Component } from 'react';

import './licenses.css';

class SoftwareLicenses extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <a href="/">
                            <img src="/feature-square.png" alt="EventBug Logo" className="center" />
                        </a>
                    </div>    
                </div>


                <div className="row">
                    <div className="col s6 m3 offset-m4">
                        <h5>jszip</h5>

                        <p><a target="_blank" rel="noopener noreferrer" href="https://github.com/Stuk/jszip">Goto Website</a></p>
                        <p><a target="_blank" rel="noopener noreferrer" href="https://raw.githubusercontent.com/Stuk/jszip/master/LICENSE.markdown">Goto License</a></p>
                    </div>

                    <div className="col s6 m3">
                        <h5>jszip-utils</h5>

                        <p><a target="_blank" rel="noopener noreferrer" href="https://github.com/Stuk/jszip-utils">Goto Website</a></p>
                        <p><a target="_blank" rel="noopener noreferrer" href="https://raw.githubusercontent.com/Stuk/jszip-utils/master/LICENSE.markdown">Goto License</a></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col s6 m3 offset-m4">
                        <h5>file-saver</h5>

                        <p><a target="_blank" rel="noopener noreferrer" href="https://github.com/eligrey/FileSaver.js">Goto Website</a></p>
                        <p><a target="_blank" rel="noopener noreferrer" href="https://raw.githubusercontent.com/eligrey/FileSaver.js/master/LICENSE.md">Goto License</a></p>
                    </div>

                    <div className="col s6 m3">
                        <h5>firebaseui-web</h5>

                        <p><a target="_blank" rel="noopener noreferrer" href="https://github.com/firebase/firebaseui-web">Goto Website</a></p>
                        <p><a target="_blank" rel="noopener noreferrer" href="https://raw.githubusercontent.com/firebase/firebaseui-web/master/LICENSE">Goto License</a></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col s6 m3 offset-m4">
                        <h5>react-photo-gallery</h5>

                        <p><a target="_blank" rel="noopener noreferrer" href="http://neptunian.github.io/react-photo-gallery/">Goto Website</a></p>
                        <p><a target="_blank" rel="noopener noreferrer" href="https://raw.githubusercontent.com/neptunian/react-photo-gallery/master/LICENSE">Goto License</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SoftwareLicenses;